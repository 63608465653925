<template>
  <div class="read-more-container" :class="{'is-active': opened }">
    <div class="read-more">
      <slot />
    </div>
    <button type="button" class="button is-primary" @click="opened =! opened">Read {{opened ? 'Less' : 'More'}}</button>
  </div>
</template>

<script>
export default {
  name: 'read-more',
  data() {
    return {
      opened: false
    }
  },
}
</script>

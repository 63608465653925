var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "level" }, [
      _c("div", { staticClass: "level-start" }, [
        _c("div", { staticClass: "level-item" }, [
          _c(
            "div",
            { staticClass: "buttons" },
            _vm._l(
              [
                "All",
                "Belfast",
                "Liverpool",
                "Newcastle",
                "Dubai",
                "Manchester",
                "Middlesbrough"
              ],
              function(cat, index) {
                return _c(
                  "a",
                  {
                    key: index,
                    staticClass: "button is-primary",
                    class: { "is-link": _vm.category === cat },
                    on: {
                      click: function($event) {
                        return _vm.setFilter(cat)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(cat))]
                )
              }
            ),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "level-end" }, [
        _c("div", { staticClass: "level-item" }, [
          _vm.pageCount > 1
            ? _c(
                "nav",
                { staticClass: "buttons" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.prev()
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.pageCount, function(p) {
                    return [
                      _vm.pageNo == p
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-primary",
                              on: {
                                click: function($event) {
                                  return _vm.page(p)
                                }
                              }
                            },
                            [_vm._v(_vm._s(p))]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.page(p)
                                }
                              }
                            },
                            [_vm._v(_vm._s(p))]
                          )
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("Next page")]
                  )
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.items.length > 0
      ? _c("div", [
          _c(
            "ul",
            { staticClass: "columns is-multiline" },
            _vm._l(_vm.items, function(item, index) {
              return _c(
                "li",
                { key: index, staticClass: "column is-6-tablet is-4-desktop" },
                [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "a",
                      { staticClass: "card-image", attrs: { href: item.url } },
                      [
                        _c("div", { staticClass: "card-image__img" }, [
                          _c("img", {
                            attrs: {
                              src: item.image,
                              alt: item.title + ", " + item.property
                            }
                          })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-content" }, [
                      _c("h3", { staticClass: "card-content__title" }, [
                        _c("a", { attrs: { href: item.url } }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(item.title) +
                              ", " +
                              _vm._s(item.property) +
                              ", " +
                              _vm._s(item.city) +
                              "\n                "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(item.address) +
                            ", " +
                            _vm._s(item.postcode) +
                            " "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-footer" }, [
                      _c("div", { staticClass: "level" }, [
                        _c("div", { staticClass: "level-left" }, [
                          _c("div", { staticClass: "level-item" }, [
                            item.price
                              ? _c("p", [
                                  _vm._v("from  "),
                                  _c("strong", [
                                    _vm._v("£" + _vm._s(item.price))
                                  ]),
                                  _vm._v("/night")
                                ])
                              : _vm._e()
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "level-right" }, [
                          _c("div", { staticClass: "level-item" }, [
                            item.booking && item.booking !== "/booking"
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "button is-link",
                                    attrs: {
                                      target: "_blank",
                                      rel: "noopener nofollow noindex",
                                      href: item.booking
                                    }
                                  },
                                  [_vm._v("Book now")]
                                )
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ])
                ]
              )
            }),
            0
          )
        ])
      : _c("div", [_c("p", [_vm._v("No results")])]),
    _vm._v(" "),
    _c("div", { staticClass: "space" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        staticClass: "navbar is-primary is-boxed",
        attrs: { role: "navigation", "aria-label": "main navigation" }
      },
      [
        _c(
          "div",
          { staticClass: "navbar-brand" },
          [
            _vm._t("brand"),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-item is-hidden-desktop",
                attrs: {
                  "aria-label": "Call us now",
                  href: "tel:00442895216054"
                },
                on: {
                  click: function($event) {
                    return _vm.raportClick()
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      stroke: "currentColor",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "aria-hidden": "true",
                      "data-reactid": "951"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                      }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "navbar-item is-hidden-desktop" },
              [_vm._t("book")],
              2
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "navbar-burger burger",
                class: [{ "is-active": _vm.isOpen }],
                attrs: {
                  role: "button",
                  "aria-label": "menu",
                  "aria-expanded": "false"
                },
                on: {
                  click: function($event) {
                    return _vm.toggleMenu()
                  }
                }
              },
              [
                _c("span", { attrs: { "aria-hidden": "true" } }),
                _vm._v(" "),
                _c("span", { attrs: { "aria-hidden": "true" } }),
                _vm._v(" "),
                _c("span", { attrs: { "aria-hidden": "true" } })
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "navbar-menu is-unselectable",
            class: [{ "is-active": _vm.isOpen }]
          },
          [
            _c("div", { staticClass: "navbar-start" }, [_vm._t("default")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "navbar-end" }, [
              _c("div", { staticClass: "navbar-item" }, [_vm._t("book")], 2)
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$enquire.show,
            expression: "$enquire.show"
          }
        ],
        staticClass: "popup"
      },
      [
        _c("div", { staticClass: "popup__container" }, [
          _c("div", { staticClass: "popup__content" }, [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.name,
                              expression: "form.name"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            name: "name",
                            type: "text",
                            placeholder: "Name"
                          },
                          domProps: { value: _vm.form.name },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "name", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("name"),
                                expression: "errors.has('name')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("name")))]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [_vm._v("Email")]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.email,
                              expression: "form.email"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "email",
                            name: "email",
                            placeholder: "Email"
                          },
                          domProps: { value: _vm.form.email },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "email", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("email"),
                                expression: "errors.has('email')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("email")))]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Contact phone number")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.phone,
                              expression: "form.phone"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "tel",
                            name: "phone",
                            placeholder: "Phone number"
                          },
                          domProps: { value: _vm.form.phone },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "phone", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("phone"),
                                expression: "errors.has('phone')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("phone")))]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Number of guests")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|numeric",
                              expression: "'required|numeric'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.guests,
                              expression: "form.guests"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "number",
                            name: "guests",
                            placeholder: " Number of guests"
                          },
                          domProps: { value: _vm.form.guests },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "guests", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("guests"),
                                expression: "errors.has('guests')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("guests")))]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "field-body" }, [
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Check-in")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|date_format:yyyy-mm-dd",
                              expression: "'required|date_format:yyyy-mm-dd'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.checkin,
                              expression: "form.checkin"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "date",
                            name: "checkin",
                            placeholder: "Check-in date"
                          },
                          domProps: { value: _vm.form.checkin },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.form, "checkin", $event.target.value)
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("checkin"),
                                expression: "errors.has('checkin')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("checkin")))]
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "field" }, [
                      _c("label", { staticClass: "label" }, [
                        _vm._v("Check-out")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "control" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|date_format:yyyy-mm-dd",
                              expression: "'required|date_format:yyyy-mm-dd'"
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.form.checkout,
                              expression: "form.checkout"
                            }
                          ],
                          staticClass: "input",
                          attrs: {
                            type: "date",
                            name: "checkout",
                            placeholder: "Check-out date"
                          },
                          domProps: { value: _vm.form.checkout },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.form,
                                "checkout",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.errors.has("checkout"),
                                expression: "errors.has('checkout')"
                              }
                            ],
                            staticClass: "help is-danger"
                          },
                          [_vm._v(_vm._s(_vm.errors.first("checkout")))]
                        )
                      ])
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Message")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "control" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.message,
                          expression: "form.message"
                        }
                      ],
                      staticClass: "textarea",
                      attrs: { name: "message", placeholder: "Message" },
                      domProps: { value: _vm.form.message },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.form, "message", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("message"),
                            expression: "errors.has('message')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("message")))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field" }, [
                  _c("div", { staticClass: "control" }, [
                    _c("label", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        attrs: { name: "agree", type: "checkbox" }
                      }),
                      _vm._v("\n                  I agree to the "),
                      _c("a", { attrs: { href: "/terms-and-conditions" } }, [
                        _vm._v("terms and conditions")
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("agree"),
                            expression: "errors.has('agree')"
                          }
                        ],
                        staticClass: "help is-danger"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("agree")))]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "field is-grouped" }, [
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.Close()
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-link",
                        attrs: { disabled: _vm.errors.any(), type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.SendMessage()
                          }
                        }
                      },
                      [_vm._v("Submit")]
                    )
                  ])
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "vue-lb-overlay",
          staticStyle: { opacity: "1" },
          on: {
            click: function($event) {
              return _vm.Close()
            }
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { "min-height": "200px", height: "100%" },
      attrs: { id: "map" }
    },
    [
      _c(
        "l-map",
        {
          staticStyle: { height: "100%", "z-index": "0", position: "relative" },
          attrs: { zoom: _vm.zoom, center: _vm.center }
        },
        [
          _c("l-tile-layer", {
            attrs: { url: _vm.url, attribution: _vm.attribution }
          }),
          _vm._v(" "),
          _vm._l(_vm.markers, function(m, index) {
            return _c(
              "l-marker",
              { key: index, attrs: { "lat-lng": m.position, icon: _vm.icon } },
              [
                _c("l-icon", { attrs: { "icon-anchor": _vm.staticAnchor } }, [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/map-pin.svg",
                      alt: "",
                      width: "48",
                      height: "48"
                    }
                  })
                ])
              ],
              1
            )
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div id="map" style="min-height:200px;height: 100%">
    <l-map :zoom="zoom" :center="center" style="height:100%;z-index:0;position:relative;">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <l-marker :lat-lng="m.position" :icon="icon" v-for="(m, index) in markers" :key="index">
          <l-icon :icon-anchor="staticAnchor">
            <img src="/assets/images/map-pin.svg" alt="" width="48" height="48" />
          </l-icon>
        </l-marker>

    </l-map>
 </div>
</template>
<script>
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet'

export default {
  name: 'Map',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  props:{
    zoom: Number,
    center: Object,
    markers: Array
  },
  data () {
    return {
      statusText: '',
      selected: null,
      url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
      attribution: ' <a href="http://osm.org/copyright">OpenStreetMap</a>',
      icon: icon({
        iconUrl: '/assets/images/map-pin.svg',
        iconSize: [48, 48],
        iconAnchor: [0, 0]
      }),
      staticAnchor: [0, 24]
    }
  },
  methods: {

  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { position: "relative" } },
    [
      _c(
        "swiper",
        { ref: "slider", attrs: { options: _vm.options } },
        _vm._l(_vm.gallery, function(image, index) {
          return _c("swiper-slide", { key: index }, [
            _c("div", { staticClass: "card is-centered" }, [
              _c(
                "div",
                {
                  staticClass: "card-image",
                  on: {
                    click: function($event) {
                      return _vm.openGallery(index)
                    }
                  }
                },
                [
                  _c("picture", { staticClass: "card-image__img" }, [
                    _c("img", {
                      directives: [
                        {
                          name: "lazy",
                          rawName: "v-lazy",
                          value: image.url + "?w=480&h=340&fit=crop",
                          expression: "image.url+'?w=480&h=340&fit=crop'"
                        }
                      ],
                      attrs: { alt: "", lazy: "loading" }
                    })
                  ])
                ]
              )
            ])
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-prev" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "48",
              height: "48",
              viewBox: "0 0 24 24",
              fill: "none",
              stroke: "currentColor",
              "stroke-width": "1",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "aria-hidden": "true"
            }
          },
          [_c("polyline", { attrs: { points: "15 18 9 12 15 6" } })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-next" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "48",
              height: "48",
              viewBox: "0 0 24 24",
              fill: "none",
              stroke: "currentColor",
              "stroke-width": "1",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "aria-hidden": "true"
            }
          },
          [_c("polyline", { attrs: { points: "9 18 15 12 9 6" } })]
        )
      ]),
      _vm._v(" "),
      _c("iamLightBox", {
        ref: "lightbox",
        attrs: {
          images: _vm.gallery,
          "show-light-box": false,
          "show-caption": true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
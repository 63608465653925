var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "swiper",
        { ref: "slider", attrs: { options: _vm.options } },
        [
          _vm._l(_vm.slides, function(slide, index) {
            return _c("swiper-slide", { key: index }, [
              _c("picture", { staticClass: "picture" }, [
                _c("source", {
                  attrs: {
                    media: "(max-width: 400px)",
                    srcset: slide.url + "?w=400&h=500&fit=crop"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    media: "(max-width: 600px)",
                    srcset: slide.url + "?w=600&h=600&fit=crop"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    media: "(max-width: 992px)",
                    srcset: slide.url + "?w=992&h=600&fit=crop"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    media: "(max-width: 1200px)",
                    srcset: slide.url + "?w=1200&h=600&fit=crop"
                  }
                }),
                _vm._v(" "),
                _c("source", {
                  attrs: {
                    media: "(min-width: 1201px)",
                    srcset: slide.url + "?w=" + _vm.width + "&h=800&fit=crop"
                  }
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: {
                    src: slide.url + "?w=" + _vm.width + "&h=800&fit=crop",
                    alt: _vm.title
                  }
                })
              ])
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container",
              attrs: { slot: "scrollbar" },
              slot: "scrollbar"
            },
            [
              _c("div", {
                staticClass: "header-slider-scrollbar swiper-scrollbar"
              })
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-prev" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "48",
              height: "48",
              viewBox: "0 0 24 24",
              fill: "none",
              stroke: "currentColor",
              "stroke-width": "1",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "aria-hidden": "true"
            }
          },
          [_c("polyline", { attrs: { points: "15 18 9 12 15 6" } })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "swiper-button-next" }, [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "48",
              height: "48",
              viewBox: "0 0 24 24",
              fill: "none",
              stroke: "currentColor",
              "stroke-width": "1",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
              "aria-hidden": "true"
            }
          },
          [_c("polyline", { attrs: { points: "9 18 15 12 9 6" } })]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "swiper-property" },
    [
      _c(
        "swiper",
        { ref: "sliderProperty", attrs: { options: _vm.options } },
        _vm._l(_vm.slides, function(slide, index) {
          return _c("swiper-slide", { key: index }, [
            _c("img", { attrs: { src: slide.url, alt: slide.title } })
          ])
        }),
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "swiper-pagination",
        class: "swiper-pagination-" + _vm.title
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
// Loaded after CoreUI app.js

// Start of Zendesk Chat Script

if (navigator.onLine !== false) {

  setTimeout(function(){
    window.$zopim||(function(d,s){var z=$zopim=function(c){z._.push(c)},$=z.s=
    d.createElement(s),e=d.getElementsByTagName(s)[0];z.set=function(o){z.set.
    _.push(o)};z._=[];z.set._=[];$.async=!0;$.setAttribute("charset","utf-8");
    $.src="https://v2.zopim.com/?4MyJZK33DhCKFNJAnVylft1VIzzHEQqW";z.t=+new Date;$.
    type="text/javascript";e.parentNode.insertBefore($,e)})(document,"script");
    setTimeout(function(){
      // let chat = document.querySelectorAll('[data-test-id=ChatWidgetWindow-iframe]');
      // chat[0].setAttribute('title', 'Chat Widget Window');
    }, 1000);
   }, 3000);

}

// End of Zendesk Chat Script


/*
if ('serviceWorker' in navigator ) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/service-worker.js').then(function(registration) {
            // Registration was successful
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
            // registration failed :(
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}
*/

// ==ClosureCompiler==
// @compilation_level SIMPLE_OPTIMIZATIONS
// @output_file_name EventListenerOptions.shim.min.js
// @language ECMASCRIPT5
// ==/ClosureCompiler==

(function() {
  var supportsPassive = false;
  document.createElement("div").addEventListener("test", function() {}, {
    get passive() {
      supportsPassive = true;
      return false;
    }
  });

  if (!supportsPassive) {
    var super_add_event_listener = EventTarget.prototype.addEventListener;
    var super_remove_event_listener = EventTarget.prototype.removeEventListener;
    var super_prevent_default = Event.prototype.preventDefault;

    function parseOptions(type, listener, options, action) {
      var needsWrapping = false;
      var useCapture = false;
      var passive = false;
      var fieldId;
      if (options) {
        if (typeof(options) === 'object') {
          passive = options.passive ? true : false;
          useCapture = options.useCapture ? true : false;
        } else {
          useCapture = options;
        }
      }
      if (passive)
        needsWrapping = true;
      if (needsWrapping) {
        fieldId = useCapture.toString();
        fieldId += passive.toString();
      }
      action(needsWrapping, fieldId, useCapture, passive);
    }

    Event.prototype.preventDefault = function() {
      if (this.__passive) {
        console.warn("Ignored attempt to preventDefault an event from a passive listener");
        return;
      }
      super_prevent_default.apply(this);
    }

    EventTarget.prototype.addEventListener = function(type, listener, options) {
      var super_this = this;
      parseOptions(type, listener, options,
        function(needsWrapping, fieldId, useCapture, passive) {
          if (needsWrapping) {
            var fieldId = useCapture.toString();
            fieldId += passive.toString();

            if (!this.__event_listeners_options)
              this.__event_listeners_options = {};
            if (!this.__event_listeners_options[type])
              this.__event_listeners_options[type] = {};
            if (!this.__event_listeners_options[type][listener])
              this.__event_listeners_options[type][listener] = [];
            if (this.__event_listeners_options[type][listener][fieldId])
              return;
            var wrapped = {
              handleEvent: function (e) {
                e.__passive = passive;
                if (typeof(listener) === 'function') {
                  listener(e);
                } else {
                  listener.handleEvent(e);
                }
                e.__passive = false;
              }
            };
            this.__event_listeners_options[type][listener][fieldId] = wrapped;
            super_add_event_listener.call(super_this, type, wrapped, useCapture);
          } else {
            super_add_event_listener.call(super_this, type, listener, useCapture);
          }
        });
    }

    EventTarget.prototype.removeEventListener = function(type, listener, options) {
      var super_this = this;
      parseOptions(type, listener, options,
        function(needsWrapping, fieldId, useCapture, passive) {
          if (needsWrapping &&
              this.__event_listeners_options &&
              this.__event_listeners_options[type] &&
              this.__event_listeners_options[type][listener] &&
              this.__event_listeners_options[type][listener][fieldId]) {
            super_remove_event_listener.call(super_this, type, this.__event_listeners_options[type][listener][fieldId], false);
            delete this.__event_listeners_options[type][listener][fieldId];
            if (this.__event_listeners_options[type][listener].length == 0)
              delete this.__event_listeners_options[type][listener];
          } else {
            super_remove_event_listener.call(super_this, type, listener, useCapture);
          }
        });
    }
  }
})();

<template>
  <div style="padding:1rem; background:#b39066">
    <form ref="form" :action="action" @submit.stop="CheckAvailability" method="POST">
      <div class="columns is-mobile is-multiline"  style="margin:-0.25rem;">
        <div class="column is-12-mobile is-5-tablet" style="padding:0.25rem;">

          <div class="select" style="width:100%;">
            <select style="width:100%;" v-model="hotel" name="hotel">
    					<option value="*">ALL APARTMENTS</option>
    					<option value="BELFAST">-BELFAST APARTMENTS</option>
    						<option value="Dream Apartments Belfast">-- Dream Apartments Belfast</option>
    						<option value="Dream Apartments Pods">-- Dream Apartments Pods</option>
    						<option value="Dream Apartments St Thomas Hall">-- Dream Apartments St Thomas Hall</option>
    					  <option value="Silkhouse Court">-LIVERPOOL APARTMENTS</option>
                <option value="Silkhouse Court">-- Dream Apartments Silkhouse Court</option>
                <option value="Dream Apartments Dale Street Liverpool">-- Dream Apartments Dale Street</option>
    					  <option value="NEWCASTLE">-NEWCASTLE APARTMENTS</option>
    						<option value="Dream Apartments Kensington House">-- Dream Apartments Kensington House</option>
    						<option value="Dream Apartments Aerial House">-- Dream Apartments Aerial House</option>
    						<option value="Dream Apartments Bigg Market">-- Dream Apartments Bigg Market</option>
                <option value="MANCHESTER">-MANCHESTER APARTMENTS</option>
                <option value="Dream Apartments Adelphi Wharf">-- Dream Apartments Adelphi Wharf</option>
                <option value="MIDDLESBROUGH">-MIDDLESBROUGH</option>
                <option value="Dream Apartments Middlesbrough">-- Dream Apartments Boho 6, Middlesbrough</option>
                <option value="Dream Apartments Dundee">-DUNDEE</option>
    				</select>
          </div>
        </div>
        <div class="column is-12-mobile is-5-tablet" style="padding:0.25rem;">
          <div>
            <b-datepicker
              v-model="dates"
              :min-date="minDate"
              :first-day-of-week="1"
              :mobile-native="false"
              placeholder="Select Dates"
              aria-label="Select Dates"
              range
              icon="calendar-today"
            >
            </b-datepicker>
          </div>
        </div>
        <div class="column is-12-mobile is-2-tablet" style="padding:0.25rem;">

          <input type="hidden" v-model="keyword" name="keyword" />
          <input type="hidden" v-model="nights" name="nights" />
  				<input type="hidden" v-model="checkin" name="checkin" />
  				<input type="hidden" v-model="multiRoom" name="multiRoom" />
  				<input type="hidden" v-model="fw_submitted" name="fw_submitted" />
  				<input type="hidden" v-model="av" name="av"  />
  				<input type="hidden" v-model="promo_preserve_dates" name="promo_preserve_dates" />

          <button type="submit" class="button is-primary" value="Check Availability" style="width:100%">
            Book Now
          </button>
        </div>
      </div>
      <p class="help" v-if="verrors.length">
        <span v-for="error in verrors">{{ error }}</span>
      </p>
    </form>
  </div>
</template>
<script>
export default {
  name: 'Booking',
  data () {
    return {
      verrors: [],
      action: 'https://secure.dreamapartments.co.uk/portal/site/www.dreamapartments.co.uk/en/index.html',
      checkin: null,
      checkout: null,
      hotel: '*',
      keyword: '*',
      nights: 0,
      multiRoom: false,
      fw_submitted: 1,
      promo_preserve_dates: 1,
      av: 'search',
      minDate: undefined,
      dates: []
    }
  },
  mounted() {
    const today = new Date()
    this.minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )
  },
  methods: {
    CheckAvailability(e) {

          this.verrors = [];

          const din = new Date(this.dates[0])
          const ddin = din.getDate()
          const mmin = din.getMonth() + 1

          this.checkin = [
            din.getFullYear(),
            (mmin > 9 ? '' : '0') + mmin,
            (ddin > 9 ? '' : '0') + ddin
          ].join('-')

          const dout = new Date(this.dates[1])
          const ddout = dout.getDate()
          const mmout = dout.getMonth() + 1

          this.checkout = [
            dout.getFullYear(),
            (mmout > 9 ? '' : '0') + mmout,
            (ddout > 9 ? '' : '0') + ddout
          ].join('-')


          this.nights = Math.round(Math.abs((dout.getTime() - din.getTime()) / (1000 * 3600 * 24)))
          this.keyword = this.hotel


          if((this.hotel==="*")||(this.hotel==="BELFAST")||(this.hotel==="NEWCASTLE")||(this.hotel==="LIVERPOOL")||(this.hotel==="MANCHESTER")){
            this.action = 'https://secure.dreamapartments.co.uk/portal/site/www.dreamapartments.co.uk/en/index.html'
          }
          else{
            this.action = 'https://secure.dreamapartments.co.uk/convert/site/' + this.hotel + '/en/index.php'
          }

          if (!this.nights) {
            this.verrors.push('You have a minimum 1 night requirement for all reservation');
          }

          if (!this.verrors.length) {
              return true;
          }

          e.preventDefault();

         //  this.$refs.form.submit()


      // eslint-disable-next-line
      // console.log(url)
    }
  }
}
</script>
<style lang="scss">

</style>

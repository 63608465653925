var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.images && _vm.images.length > 0
      ? _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.lightBoxOn,
                expression: "lightBoxOn"
              }
            ],
            ref: "container",
            staticClass: "vue-lb-container"
          },
          [
            _c(
              "button",
              {
                staticClass: "vue-lb-button-close",
                attrs: { type: "button", title: "Close (Esc)" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeLightBox($event)
                  }
                }
              },
              [
                _c(
                  "svg",
                  {
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      width: "24",
                      height: "24",
                      viewBox: "0 0 24 24",
                      fill: "none",
                      stroke: "currentColor",
                      "stroke-width": "2",
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "aria-hidden": "true"
                    }
                  },
                  [
                    _c("line", {
                      attrs: { x1: "18", y1: "6", x2: "6", y2: "18" }
                    }),
                    _c("line", {
                      attrs: { x1: "6", y1: "6", x2: "18", y2: "18" }
                    })
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "vue-lb-content" }, [
              _c(
                "div",
                { staticClass: "vue-lb-figure" },
                [
                  _c(
                    "transition",
                    { attrs: { mode: "out-in", name: "fade" } },
                    [
                      _c("img", {
                        directives: [
                          {
                            name: "lazy",
                            rawName: "v-lazy",
                            value: _vm.images[_vm.select].url,
                            expression: "images[select].url"
                          }
                        ],
                        key: _vm.images[_vm.select].url,
                        staticClass: "vue-lb-modal-image",
                        attrs: { srcset: _vm.images[_vm.select].srcset || "" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.showCaption && _vm.images[_vm.select].caption,
                        expression: "showCaption && images[select].caption"
                      }
                    ],
                    staticClass: "vue-lb-info",
                    domProps: {
                      innerHTML: _vm._s(_vm.images[_vm.select].caption)
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "vue-lb-footer" }, [
                _c("div", { staticClass: "vue-lb-footer-info" }),
                _vm._v(" "),
                _c("div", { staticClass: "vue-lb-footer-count" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.select + 1) +
                      "/" +
                      _vm._s(_vm.images.length) +
                      "\n        "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "vue-lb-thumbnail-wrapper" }, [
              _vm.showThumbs
                ? _c(
                    "div",
                    { staticClass: "vue-lb-thumbnail" },
                    [
                      _vm.images.length > 1
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "vue-lb-thumbnail-arrow vue-lb-thumbnail-left",
                              attrs: { type: "button", title: "Previous" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.previousImage()
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("polyline", {
                                    attrs: { points: "15 18 9 12 15 6" }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm._l(_vm.imagesThumb, function(image, index) {
                        return _c("div", {
                          directives: [
                            {
                              name: "lazy",
                              rawName: "v-lazy:background-image",
                              value: image,
                              expression: "image",
                              arg: "background-image"
                            },
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                index >= _vm.thumbIndex.begin &&
                                index <= _vm.thumbIndex.end,
                              expression:
                                "index >= thumbIndex.begin && index <= thumbIndex.end"
                            }
                          ],
                          key: index,
                          class:
                            "vue-lb-modal-thumbnail" +
                            (_vm.select === index ? "-active" : ""),
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.showImage(index)
                            }
                          }
                        })
                      }),
                      _vm._v(" "),
                      _vm.images.length > 1
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "vue-lb-thumbnail-arrow vue-lb-thumbnail-right",
                              attrs: { type: "button", title: "Next" },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.nextImage()
                                }
                              }
                            },
                            [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "24",
                                    height: "24",
                                    viewBox: "0 0 24 24",
                                    fill: "none",
                                    stroke: "currentColor",
                                    "stroke-width": "2",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("polyline", {
                                    attrs: { points: "9 18 15 12 9 6" }
                                  })
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.images.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "vue-lb-arrow vue-lb-left",
                    attrs: { type: "button", title: "Previous" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.previousImage()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "aria-hidden": "true"
                        }
                      },
                      [_c("polyline", { attrs: { points: "15 18 9 12 15 6" } })]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.images.length > 1
              ? _c(
                  "button",
                  {
                    staticClass: "vue-lb-arrow vue-lb-right",
                    attrs: { type: "button", title: "Next" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.nextImage()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "24",
                          height: "24",
                          viewBox: "0 0 24 24",
                          fill: "none",
                          stroke: "currentColor",
                          "stroke-width": "2",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                          "aria-hidden": "true"
                        }
                      },
                      [_c("polyline", { attrs: { points: "9 18 15 12 9 6" } })]
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", {
              staticClass: "vue-lb-overlay",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.closeLightBox($event)
                }
              }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
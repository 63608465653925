<template>
  <div>
    <div class="level">
      <div class="level-start">
        <div class="level-item">
          <nav class="buttons" v-if="pageCount > 1">
            <a @click="prev()" class="button">Previous</a>
              <template v-for="p in pageCount">
                <a v-if="pageNo == p" @click="page(p)" class="button is-primary">{{p}}</a>
                <a v-else @click="page(p)" class="button">{{p}}</a>
              </template>
            <a @click="next()" class="button">Next page</a>
          </nav>
        </div>
      </div>
    </div>
    <ul class="columns is-multiline news-grid">
      <li class="column is-4" v-for='(item, index) in items' :key='index'>
        <div class="card">
          <a class="card-image" :href="item.url">
            <div class="card-image__img">
              <img :src="item.image" :alt="item.title">
            </div>
          </a>
          <div class="card-content">
            <strong class="latest-news-date">{{ item.date }}</strong>
            <h3 class="card-content__title"><a :href="item.url">{{ item.title }}</a></h3>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
    export default {
      name: 'NewsIndex',
      data () {
        return {
          news: [],
          items: [],
          pageNo: 1,
          pageSize: 12,
          pageCount: 0
        }
      },
      methods: {
        init(){
          this.virtualService({
            pageNo: this.pageNo,
            pageSize: this.pageSize
          })
        },
        page(pageNo) {
          this.virtualService({
            pageNo: pageNo,
            pageSize: this.pageSize
          })
        },
        first() {
          this.pageNo = 1
          this.virtualService({
            pageNo:this.pageNo,
            pageSize:this.pageSize
          })
        },
        last() {
          this.pageNo = this.pageCount
          this.virtualService({
            pageNo:this.pageNo,
            pageSize:this.pageSize
          })
        },
        prev() {
          if(this.pageNo > 1){
            this.pageNo -= 1
            this.virtualService({
              pageNo:this.pageNo,
              pageSize:this.pageSize
            })
          }
        },
        next() {
          if(this.pageNo < this.pageCount) {
            this.pageNo += 1
            this.virtualService({
              pageNo:this.pageNo,
              pageSize:this.pageSize
            })
          }
        },
        count(condition){
          return this.news.length
        },
        queryFromVirtualDB(condition, startRow, endRow){

          var result = []
          var condition = {}
          var data = this.news
          var count = this.news.length
          for(var i = startRow - 1; i < endRow; i++) {
            if(i < count){
              result.push(data[i])
            }
          }
          return result
        },
        virtualService(params){
          var result = []
          var condition = {}
          var pageNo = params.pageNo
          var pageSize = params.pageSize
          var pageCount = Math.ceil(this.news.length / pageSize)

          if (pageNo == 0) pageNo = 1
          if (pageNo < 0) pageNo = pageCount
          else if (pageNo > pageCount) pageNo = pageCount
          var startRow = (pageNo - 1) * pageSize + 1
    		  var endRow = startRow + pageSize - 1
          var data = this.queryFromVirtualDB(condition, startRow, endRow)

          // set result
          this.items = data
          this.pageNo = pageNo
          this.pageCount = pageCount
        }
      },
      mounted(){
        let uri = '/api/news'
        axios.get(uri)
        .then(response => {
            this.news = response.data
            this.init()
          })
        .catch(error => {
          console.log(error)
        })
      }
    }
</script>

<template>
  <div>
    <swiper ref="slider" :options="options">
      <swiper-slide v-for="(slide, index) in slides" :key="index">
        <picture class="picture">
          <source media="(max-width: 400px)" :srcset="slide.url+'?w=400&h=500&fit=crop'">
          <source media="(max-width: 600px)" :srcset="slide.url+'?w=600&h=600&fit=crop'">
          <source media="(max-width: 992px)" :srcset="slide.url+'?w=992&h=600&fit=crop'">
          <source media="(max-width: 1200px)" :srcset="slide.url+'?w=1200&h=600&fit=crop'">
          <source media="(min-width: 1201px)" :srcset="slide.url+'?w='+ width +'&h=800&fit=crop'">
          <img :src="slide.url+'?w='+ width +'&h=800&fit=crop'" :alt="title" >
        </picture>
      </swiper-slide>
      <div class="container" slot="scrollbar">
        <div class="header-slider-scrollbar swiper-scrollbar"></div>
      </div>
    </swiper>

    <div class="swiper-button-prev">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true"><polyline points="15 18 9 12 15 6"></polyline></svg>
    </div>
    <div class="swiper-button-next">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true"><polyline points="9 18 15 12 9 6"></polyline></svg>
    </div>
  </div>
</template>
<script>
export default {
  name: 'slider',
  props: {
    title: {
      type: String,
    },
    width: {
      type: Number,
      default: 1700
    },
    slides: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        spaceBetween: 0,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        effect: 'fade',
        init: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false
        },
        on: {
          init() {
            window.dispatchEvent(new Event('resize'))
          }
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.slider.swiper
    }
  },
  mounted () {
    // this.swiper.slideTo(3, 1000, false)
    setTimeout(() => {
      this.swiper.init()
    }, 1000)
  }
}
</script>

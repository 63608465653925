<template>
<div>
  <form  @submit.stop>
    <div class="field">
      <label class="label">Name</label>
      <div class="control">
        <input name="name" v-validate="'required'" v-model="form.name" class="input" type="text" placeholder="Name">
        <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
      </div>
    </div>

    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <input v-validate="'required|email'" v-model="form.email" class="input" type="email" name="email" placeholder="Email input">
        <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
      </div>
    </div>

    <div class="field">
      <label class="label">Subject</label>
      <div class="control">
        <input v-validate="'required'" name="subject" v-model="form.subject" class="input" type="text" placeholder="Subject">
        <span v-show="errors.has('subject')" class="help is-danger">{{ errors.first('subject') }}</span>
      </div>
    </div>

    <div class="field">
      <label class="label">Message</label>
      <div class="control">
        <textarea name="message" v-validate="'required'" v-model="form.message" class="textarea" placeholder="Textarea"></textarea>
        <span v-show="errors.has('message')" class="help is-danger">{{ errors.first('message') }}</span>
      </div>
    </div>

    <div class="field">
      <div class="control">
        <label class="checkbox">
          <input name="agree" type="checkbox" v-validate="'required'">
          I agree to the <a href="/terms-and-conditions">terms and conditions</a>
        </label>
        <span v-show="errors.has('agree')" class="help is-danger">{{ errors.first('agree') }}</span>
      </div>
    </div>

    <div class="field is-grouped">
      <div class="control">
        <button :disabled="errors.any()" type="button" class="button is-link" @click="SendMessage">Submit</button>
      </div>
    </div>
  </form>
</div>
</template>

<script>
export default {
  name: 'contactForm',
  data () {
    return {
      form: {}
    }
  },
  methods: {
    Reset () {
      this.form = {} // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/contact/send', this.form)
            .then(response => {
              this.form = {} // Clear input fields.
              //this.$notify({
              //  title: response.data.status,
              //  message: response.data.message
            //  })

            this.$gtag.reportConversion({
              'send_to': 'AW-793825595/noYZCJzvg5sBELuiw_oC'
            })

            })
            .catch(err => {
            //  this.$notify({
            //    title: err.response.data.status,
            //    message: err.response.data.message
            //  })
            })
        }
      })
    }
  }
}
</script>

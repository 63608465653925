
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap')

import 'typeface-roboto'

window.Vue = require('vue')
import Buefy from 'buefy'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VuePlyr from 'vue-plyr'
import SocialSharing from 'vue-social-sharing'
import VueLazyLoad from 'vue-lazyload'
import VeeValidate from 'vee-validate'
import VueCookie from 'vue-cookie'
//import LatestNews from './components/LatestNews/'
import NewsIndex from './components/News/'
import IamElements from './elements/'
import VueGtag from './components/vue-gtag-conversion/'
Vue.use(Buefy)
// Tell Vue to use the plugin


Vue.component('mobile-menu', require('./components/MobileMenu.vue').default)
Vue.component('gallery', require('./components/Gallery.vue').default)
Vue.component('gmap', require('./components/Map.vue').default)
Vue.component('slider', require('./components/Slider.vue').default)
Vue.component('slider-property', require('./components/SliderProperty.vue').default)
Vue.component('stag-and-hen', require('./components/StagAndHen.vue').default)
Vue.component('special-offers', require('./components/SpecialOffers.vue').default)
Vue.component('booking', require('./components/Booking.vue').default)
Vue.component('book-form', require('./components/BookForm.vue').default)
Vue.component('read-more', require('./components/ReadMore.vue').default)
Vue.component('contact-form', require('./components/ContactForm.vue').default)
Vue.component('enquiry-form', require('./components/EnquiryForm.vue').default)
Vue.component('newsletter-button', require('./components/NewsletterButton.vue').default)
Vue.component('testimonials', require('./components/Testimonials.vue').default)
Vue.component('phone-link', require('./components/PhoneLink.vue').default)
Vue.component('book-button', require('./components/BookButton.vue').default)

Vue.use(VueGtag, {
  id: process.env.MIX_GOOGLE_ADWORDS_ID, // Your Adwords ID
  enabled: process.env.NODE_ENV !== 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: process.env.NODE_ENV !== 'production' (optional)
  debug: process.env.NODE_ENV !== 'production' // Whether or not display console log debugs (optional)
})

var enquire = new Vue({ data: { show: false } })
enquire.install = function () {
  Object.defineProperty(Vue.prototype, '$enquire', {
    get () { return enquire }
  })
}
Vue.use(enquire)

Vue.use(VueLazyLoad, {
  preLoad: 1.3,
  attempt: 1
})

Vue.use(VueCookie)
Vue.use(SocialSharing)
Vue.use(VueAwesomeSwiper)
Vue.use(VuePlyr)
//Vue.use(LatestNews)
Vue.use(NewsIndex)
Vue.use(IamElements)
Vue.use(VeeValidate);

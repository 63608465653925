<template>
  <a href="tel:00442895216054" @click="raportClick()">
    <strong>+44 2895 216 054</strong>
  </a>
</template>
<script>
    export default {
      methods: {
        raportClick () {
          this.$gtag.reportConversion({
            'send_to': 'AW-793825595/t-7zCMjrg5sBELuiw_oC'
          })
        }
      }
    }
</script>

import Collapse from './collapse/'
import CollapseItem from './collapse-item/'
import Lightbox from './lightbox/'

const elements = [
  Collapse,
  CollapseItem,
  Lightbox
]

const install = function (Vue, opts = {}) {
  elements.forEach(element => {
    Vue.component(element.name, element)
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue)
}

export default install

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testimonials" },
    [
      _c(
        "swiper",
        { ref: "testimonialsSlider", attrs: { options: _vm.swiperOption } },
        [
          _vm._l(_vm.testimonials, function(testimonial, index) {
            return _c("swiper-slide", { key: index }, [
              _c("div", [
                _c("div", { staticClass: "container" }, [
                  _c("h5", { staticClass: "subtitle uppercase" }, [
                    _vm._v("SOME GUEST TESTIMONIALS")
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "title" }, [
                    _vm._v(_vm._s(testimonial.content))
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "subtitle" }, [
                    _vm._v(
                      "— " +
                        _vm._s(testimonial.author) +
                        " — visited " +
                        _vm._s(testimonial.city)
                    )
                  ])
                ])
              ])
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "container",
              attrs: { slot: "scrollbar" },
              slot: "scrollbar"
            },
            [_c("div", { staticClass: "swiper-scrollbar" })]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ContactForm.vue?vue&type=template&id=f311fc74&"
import script from "./ContactForm.vue?vue&type=script&lang=js&"
export * from "./ContactForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/web/dream-web/dreamapartments.co.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f311fc74')) {
      api.createRecord('f311fc74', component.options)
    } else {
      api.reload('f311fc74', component.options)
    }
    module.hot.accept("./ContactForm.vue?vue&type=template&id=f311fc74&", function () {
      api.rerender('f311fc74', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/frontend/components/ContactForm.vue"
export default component.exports
<template>
  <div>
    <div class="level">
      <div class="level-start">
        <div class="level-item">
          <div class="buttons">
            <a class="button is-primary" v-for="(cat, index) in ['All','Belfast','Liverpool','Newcastle', 'Dubai', 'Manchester', 'Middlesbrough']" :key="index" @click="setFilter(cat)" :class="{ 'is-link': category === cat }"> {{ cat }}</a>
          </div>
        </div>
      </div>
      <div class="level-end">
        <div class="level-item">
          <nav class="buttons" v-if="pageCount > 1">
            <a @click="prev()" class="button">Previous</a>
              <template v-for="p in pageCount">
                <a v-if="pageNo == p" @click="page(p)" class="button is-primary">{{p}}</a>
                <a v-else @click="page(p)" class="button">{{p}}</a>
              </template>
            <a @click="next()" class="button">Next page</a>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="items.length > 0">
        <ul class="columns is-multiline">
          <li class="column is-6-tablet is-4-desktop" v-for='(item, index) in items' :key='index'>
            <div class="card">
              <a class="card-image" :href="item.url">
                <div class="card-image__img">
                  <img :src="item.image" :alt="item.title + ', ' + item.property">
                </div>
              </a>
              <div class="card-content">
                <h3 class="card-content__title">
                  <a :href="item.url">
                    {{ item.title }}, {{ item.property }}, {{ item.city }}
                  </a>
                </h3>
                <p>{{ item.address }}, {{ item.postcode }} </p>

              </div>
              <div class="card-footer">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item"><p v-if="item.price">from  <strong>£{{ item.price }}</strong>/night</p></div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a v-if="item.booking && item.booking!=='/booking'" target="_blank" rel="noopener nofollow noindex" :href="item.booking" class="button is-link">Book now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
        <div v-else>
          <p>No results</p>
        </div>

    <div class="space"></div>
  </div>
</template>
<script>

export default {
  name: 'Booking',
  data () {
    return {
      category: 'All',
      apartments: [],
      items: [],
      pageNo: 1,
      pageSize: 12,
      pageCount: 0
    }
  },
  computed: {
    filteredData(){
      var city = this.category
      // filter
      if(city === "All") {
        return this.apartments
      } else {
        return this.apartments.filter(function(item) {
					return item.city === city
				});
      }
    }
  },
  methods: {
    init(){
      this.virtualService({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
    },
    page(pageNo) {
      this.virtualService({
        pageNo: pageNo,
        pageSize: this.pageSize
      })
    },
    first() {
      this.pageNo = 1
      this.virtualService({
        pageNo:this.pageNo,
        pageSize:this.pageSize
      })
    },
    last() {
      this.pageNo = this.pageCount
      this.virtualService({
        pageNo:this.pageNo,
        pageSize:this.pageSize
      })
    },
    prev() {
      if(this.pageNo > 1){
        this.pageNo -= 1
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        })
      }
    },
    next() {
      if(this.pageNo < this.pageCount) {
        this.pageNo += 1
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        })
      }
    },
    virtualDataFromDb() {
      return this.apartments
    },
    count(condition){
      return this.apartments.length
    },
    setFilter(filter){
      this.items = [],
      this.pageCount = 0
      this.pageNo = 1
      this.category = filter
      this.$nextTick(() => {
        this.init()
      })
    },
    queryFromVirtualDB(condition, startRow, endRow){

      var result = []
      var condition = {}

      var data = this.filteredData
      var count = this.filteredData.length
      for(var i = startRow - 1; i < endRow; i++) {
        if(i < count){
          result.push(data[i])
        }
      }
      return result
    },
    virtualService(params){
      var result = []
      var condition = {}
      var pageNo = params.pageNo
      var pageSize = params.pageSize
      var pageCount = Math.ceil(this.filteredData.length / pageSize)

      if (pageNo == 0) pageNo = 1
      if (pageNo < 0) pageNo = pageCount
      else if (pageNo > pageCount) pageNo = pageCount
      var startRow = (pageNo - 1) * pageSize + 1
		  var endRow = startRow + pageSize - 1
      var data = this.queryFromVirtualDB(condition, startRow, endRow)

      // set result
      this.items = data
      this.pageNo = pageNo
      this.pageCount = pageCount
    }
  },
  mounted () {
    axios
    .get('/api/apartments')
    .then(response => {
        this.apartments = response.data
        this.init()
    });
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "read-more-container", class: { "is-active": _vm.opened } },
    [
      _c("div", { staticClass: "read-more" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              _vm.opened = !_vm.opened
            }
          }
        },
        [_vm._v("Read " + _vm._s(_vm.opened ? "Less" : "More"))]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <button type="button" :href="url" :target="target" class="button is-medium is-link" @click.stop="onClick()">
      <strong>{{ label }}</strong>
    </button>
  </div>
</template>
<script>
export default {
  name: 'BookButton',
  props:{
    url: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick (e){
      if (!this.url) {
        this.$enquire.show = true;
      }else{
        var win = window.open(this.url, this.target);
        win.focus();
      }
    }
  }
}
</script>

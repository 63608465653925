<template>
  <div class="testimonials">
      <swiper :options="swiperOption" ref="testimonialsSlider">
        <swiper-slide v-for="(testimonial, index) in testimonials" :key="index">
          <div>
            <div class="container">
              <h5 class="subtitle uppercase">SOME GUEST TESTIMONIALS</h5>
              <p class="title">{{  testimonial.content }}</p>
              <p class="subtitle">— {{  testimonial.author }} — visited {{ testimonial.city }}</p>
            </div>
          </div>
        </swiper-slide>
        <div class="container" slot="scrollbar">
          <div class="swiper-scrollbar"></div>
        </div>
      </swiper>
  </div>
</template>
<script>

export default {
  name: 'Testimonials',
  props: {
    testimonials: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 1,
        init: false,
        spaceBetween: 0,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: false
        }
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.testimonialsSlider.swiper
    }
  },
  mounted () {
    // this.swiper.slideTo(3, 1000, false)
    setTimeout(() => {
      this.swiper.init()
    }, 1000)
  },
  methods: {
    init () {
      window.dispatchEvent(new Event('resize'))
    }
  }
}
</script>

<template>
  <div style="position:relative">
    <swiper ref="slider" :options="options">
      <swiper-slide v-for="(image, index) in gallery" :key="index">
        <div class="card is-centered">
          <div class="card-image" @click="openGallery(index)" >
              <picture class="card-image__img"><img v-lazy="image.url+'?w=480&h=340&fit=crop'" alt="" lazy="loading"></picture>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true"><polyline points="15 18 9 12 15 6"></polyline></svg>
    </div>
    <div class="swiper-button-next">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true"><polyline points="9 18 15 12 9 6"></polyline></svg>
    </div>
    <!--div class="columns is-multiline">
      <div class="column" v-if="index < 3" v-for="(image, index) in gallery" :key="index">
        <div class="card is-centered">
          <div class="card-image" @click="openGallery(index)" >
              <picture class="card-image__img"><img v-lazy="image.url+'?w=480&h=340&fit=crop'" alt="" lazy="loading"></picture>
          </div>
        </div>
      </div>
    </div-->
    <iamLightBox
    :images="gallery"
    ref="lightbox"
    :show-light-box="false"
    :show-caption="true"
    ></iamLightBox>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'gallery',
  props: {
    gallery: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: {
        preventClicks: false,
        preventClicksPropagation: false,
        slidesPerView: 3,
        spaceBetween: 48,
        autoplay: {
          delay: 3500,
          disableOnInteraction: false
        },
        init: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        on: {
          init() {
            window.dispatchEvent(new Event('resize'))
          }
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.slider.swiper
    }
  },
  mounted () {
    // this.swiper.slideTo(3, 1000, false)
    setTimeout(() => {
      this.swiper.init()
    }, 1000)
  },
  methods: {
    openGallery (index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

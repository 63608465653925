var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "iam-collapse-item", class: { "is-active": _vm.isActive } },
    [
      _c(
        "div",
        {
          attrs: {
            role: "tab",
            "aria-expanded": _vm.isActive,
            "aria-controls": "iam-collapse-content-" + _vm.id,
            "aria-describedby": "iam-collapse-content-" + _vm.id
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "iam-collapse-item__header",
              class: {
                focusing: _vm.focusing,
                "is-active": _vm.isActive
              },
              attrs: {
                role: "button",
                id: "iam-collapse-head-" + _vm.id,
                tabindex: "0"
              },
              on: {
                click: _vm.handleHeaderClick,
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.handleEnterClick($event)
                },
                focus: _vm.handleFocus,
                blur: function($event) {
                  _vm.focusing = false
                }
              }
            },
            [
              _vm._t("title", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c("i", {
                staticClass: "iam-collapse-item__arrow iam-icon-chevron-right",
                class: { "is-active": _vm.isActive }
              })
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isActive,
              expression: "isActive"
            }
          ],
          staticClass: "iam-collapse-item__wrap",
          attrs: {
            role: "tabpanel",
            "aria-hidden": !_vm.isActive,
            "aria-labelledby": "iam-collapse-head-" + _vm.id,
            id: "iam-collapse-content-" + _vm.id
          }
        },
        [
          _c(
            "div",
            { staticClass: "iam-collapse-item__content" },
            [_vm._t("default")],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
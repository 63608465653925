var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "button is-medium is-link",
        attrs: { type: "button", href: _vm.url, target: _vm.target },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.onClick()
          }
        }
      },
      [_c("strong", [_vm._v(_vm._s(_vm.label))])]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
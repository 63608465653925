<template>
  <div>
    <nav class="navbar is-primary is-boxed" role="navigation" aria-label="main navigation">

      <div class="navbar-brand">

        <slot name="brand"></slot>

        <a aria-label="Call us now" href="tel:00442895216054" class="navbar-item is-hidden-desktop" @click="raportClick()">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" data-reactid="951"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg>
        </a>
        <div class="navbar-item is-hidden-desktop">
          <slot name="book"></slot>
        </div>
        <a @click="toggleMenu()" role="button" :class="[{'is-active' : isOpen }]" class="navbar-burger burger" aria-label="menu" aria-expanded="false">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div :class="[{'is-active' : isOpen }]" class="navbar-menu is-unselectable">
        <div class="navbar-start">
          <slot></slot>
        </div>
        <div class="navbar-end">
          <div class="navbar-item">
            <slot name="book"></slot>
          </div>
        </div>
      </div>
    </nav>

    <div v-show="$enquire.show" class="popup">
      <div class="popup__container">
        <div class="popup__content">

          <form  @submit.stop>
            <div class="field">
            <div class="field-body">

            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input name="name" v-validate="'required'" v-model="form.name" class="input" type="text" placeholder="Name">
                <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-validate="'required|email'" v-model="form.email" class="input" type="email" name="email" placeholder="Email">
                <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
              </div>
            </div>

          </div>
          </div>
          <div class="field">
          <div class="field-body">
            <div class="field">
              <label class="label">Contact phone number</label>
              <div class="control">
                <input v-validate="'required'" v-model="form.phone" class="input" type="tel" name="phone" placeholder="Phone number">
                <span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">Number of guests</label>
              <div class="control">
                <input v-validate="'required|numeric'" v-model="form.guests" class="input" type="number" name="guests" placeholder=" Number of guests">
                <span v-show="errors.has('guests')" class="help is-danger">{{ errors.first('guests') }}</span>
              </div>
            </div>

          </div>
          </div>
<div class="field">
<div class="field-body">
            <div class="field">
              <label class="label">Check-in</label>
              <div class="control">
                <input v-validate="'required|date_format:yyyy-mm-dd'" v-model="form.checkin" class="input" type="date" name="checkin" placeholder="Check-in date">
                <span v-show="errors.has('checkin')" class="help is-danger">{{ errors.first('checkin') }}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">Check-out</label>
              <div class="control">
                <input v-validate="'required|date_format:yyyy-mm-dd'" v-model="form.checkout" class="input" type="date" name="checkout" placeholder="Check-out date">
                <span v-show="errors.has('checkout')" class="help is-danger">{{ errors.first('checkout') }}</span>
              </div>
            </div>
</div>
</div>
            <div class="field">
              <label class="label">Message</label>
              <div class="control">
                <textarea name="message" v-validate="'required'" v-model="form.message" class="textarea" placeholder="Message"></textarea>
                <span v-show="errors.has('message')" class="help is-danger">{{ errors.first('message') }}</span>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input name="agree" type="checkbox" v-validate="'required'">
                  I agree to the <a href="/terms-and-conditions">terms and conditions</a>
                </label>
                <span v-show="errors.has('agree')" class="help is-danger">{{ errors.first('agree') }}</span>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button type="button" class="button" @click="Close()">Cancel</button>
                <button :disabled="errors.any()" type="button" class="button is-link" @click="SendMessage()">Submit</button>
              </div>
            </div>
          </form>


        </div>
      </div>
      <div class="vue-lb-overlay"  @click="Close()" style="opacity:1"></div>
    </div>



  </div>
</template>
<script>
    export default {
      props: ['title'],
      data () {
        return {
          form: {
            subject: this.title
          },
          isOpen: false
        }
      },
      methods: {
        raportClick() {
          this.$gtag.reportConversion({
            'send_to': 'AW-793825595/t-7zCMjrg5sBELuiw_oC'
          })
        },
        toggleMenu() {
          this.isOpen = !this.isOpen
          document.querySelector('html').classList.toggle('no-scroll', this.isOpen)
        },
        Reset () {
          this.form = {
            subject: this.title
          } // Clear input fields.
          // this.errors.clear()
        },
        SendMessage () {
          this.$validator.validateAll().then((result) => {
            if (result) {
              axios.post('/enquire/send', this.form)
                .then(response => {
                  this.Reset()
                  this.Close()

                  // Clear input fields.
                  //this.$notify({
                  //  title: response.data.status,
                  //  message: response.data.message
                //  })
    /*
                this.$gtag.reportConversion({
                  'send_to': 'AW-793825595/noYZCJzvg5sBELuiw_oC'
                })
    */
                })
                .catch(err => {
                //  this.$notify({
                //    title: err.response.data.status,
                //    message: err.response.data.message
                //  })
                })
            }
          })
        },
        Show(){
          this.$enquire.show = true
          document.querySelector('html').classList.toggle('no-scroll', true)
        },
        Close() {
          this.$enquire.show = false
          document.querySelector('html').classList.toggle('no-scroll', false)
        }
      }
    }
</script>
<style lang="scss">
@media (max-width: 1087px) {
  .navbar-menu.is-active{
   position: fixed;
   overflow-y:auto;
   -webkit-overflow-scrolling: touch;
   z-index: 1000;
   padding-left: 1rem;
   padding-right: 1rem;
   left: 0;
   right: 0;
   bottom: 0;
   top: 3.25rem;
  }
  .no-scroll{
    .navbar-brand{
      .navbar-item{
        display: none;
      }
    }
  }
}
</style>

<template>
  <button @click="Subscribe" class="button is-primary"><slot/></button>
</template>
<script>
    export default {
      data () {
        return {
        }
      },
      methods: {
        Subscribe () {
          console.log('Subscribe!')
        }
      }
    }
</script>

<template>
  <div class="swiper-property">
    <swiper ref="sliderProperty" :options="options">
      <swiper-slide v-for="(slide, index) in slides" :key="index">
          <img :src="slide.url" :alt="slide.title" >
      </swiper-slide>
    </swiper>
        <div class="swiper-pagination" :class="'swiper-pagination-' + title"></div>
  </div>
</template>
<script>
export default {
  name: 'slider-property',
  props: {
    title: {
      type: String,
      required: true
    },
    slides: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      options: {
        slidesPerView: 1.5,
        spaceBetween: 10,
        init: false,
        pagination: {
          el: '.swiper-pagination-'+this.title,
          clickable: true,
        },
        on: {
          init() {
            window.dispatchEvent(new Event('resize'))
          }
        }
      }
    }
  },
  computed: {
    swiper() {
      return this.$refs.sliderProperty.swiper
    }
  },
  mounted () {
    // this.swiper.slideTo(3, 1000, false)
    setTimeout(() => {
      this.swiper.init()
    }, 1000)
  }
}
</script>

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "level" }, [
      _c("div", { staticClass: "level-start" }, [
        _c("div", { staticClass: "level-item" }, [
          _vm.pageCount > 1
            ? _c(
                "nav",
                { staticClass: "buttons" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.prev()
                        }
                      }
                    },
                    [_vm._v("Previous")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.pageCount, function(p) {
                    return [
                      _vm.pageNo == p
                        ? _c(
                            "a",
                            {
                              staticClass: "button is-primary",
                              on: {
                                click: function($event) {
                                  return _vm.page(p)
                                }
                              }
                            },
                            [_vm._v(_vm._s(p))]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "button",
                              on: {
                                click: function($event) {
                                  return _vm.page(p)
                                }
                              }
                            },
                            [_vm._v(_vm._s(p))]
                          )
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("Next page")]
                  )
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "columns is-multiline news-grid" },
      _vm._l(_vm.items, function(item, index) {
        return _c("li", { key: index, staticClass: "column is-4" }, [
          _c("div", { staticClass: "card" }, [
            _c("a", { staticClass: "card-image", attrs: { href: item.url } }, [
              _c("div", { staticClass: "card-image__img" }, [
                _c("img", { attrs: { src: item.image, alt: item.title } })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "card-content" }, [
              _c("strong", { staticClass: "latest-news-date" }, [
                _vm._v(_vm._s(item.date))
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "card-content__title" }, [
                _c("a", { attrs: { href: item.url } }, [
                  _vm._v(_vm._s(item.title))
                ])
              ])
            ])
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
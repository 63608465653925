var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function($event) {
            $event.stopPropagation()
          }
        }
      },
      [
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name"
                }
              ],
              staticClass: "input",
              attrs: { name: "name", type: "text", placeholder: "Name" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("name"),
                    expression: "errors.has('name')"
                  }
                ],
                staticClass: "help is-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("name")))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Email")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.email,
                  expression: "form.email"
                }
              ],
              staticClass: "input",
              attrs: {
                type: "email",
                name: "email",
                placeholder: "Email input"
              },
              domProps: { value: _vm.form.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "email", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')"
                  }
                ],
                staticClass: "help is-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("email")))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Subject")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.subject,
                  expression: "form.subject"
                }
              ],
              staticClass: "input",
              attrs: { name: "subject", type: "text", placeholder: "Subject" },
              domProps: { value: _vm.form.subject },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "subject", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("subject"),
                    expression: "errors.has('subject')"
                  }
                ],
                staticClass: "help is-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("subject")))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("label", { staticClass: "label" }, [_vm._v("Message")]),
          _vm._v(" "),
          _c("div", { staticClass: "control" }, [
            _c("textarea", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.message,
                  expression: "form.message"
                }
              ],
              staticClass: "textarea",
              attrs: { name: "message", placeholder: "Textarea" },
              domProps: { value: _vm.form.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "message", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("message"),
                    expression: "errors.has('message')"
                  }
                ],
                staticClass: "help is-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("message")))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field" }, [
          _c("div", { staticClass: "control" }, [
            _c("label", { staticClass: "checkbox" }, [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                attrs: { name: "agree", type: "checkbox" }
              }),
              _vm._v("\n          I agree to the "),
              _c("a", { attrs: { href: "/terms-and-conditions" } }, [
                _vm._v("terms and conditions")
              ])
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("agree"),
                    expression: "errors.has('agree')"
                  }
                ],
                staticClass: "help is-danger"
              },
              [_vm._v(_vm._s(_vm.errors.first("agree")))]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "field is-grouped" }, [
          _c("div", { staticClass: "control" }, [
            _c(
              "button",
              {
                staticClass: "button is-link",
                attrs: { disabled: _vm.errors.any(), type: "button" },
                on: { click: _vm.SendMessage }
              },
              [_vm._v("Submit")]
            )
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="iam-collapse-item" :class="{'is-active': isActive}">
    <div
      role="tab"
      :aria-expanded="isActive"
      :aria-controls="`iam-collapse-content-${id}`"
      :aria-describedby="`iam-collapse-content-${id}`"
    >
      <div
        class="iam-collapse-item__header"
        @click="handleHeaderClick"
        role="button"
        :id="`iam-collapse-head-${id}`"
        tabindex="0"
        @keyup.space.enter.stop="handleEnterClick"
        :class="{
          'focusing': focusing,
          'is-active': isActive
        }"
        @focus="handleFocus"
        @blur="focusing = false"
      >
        <slot name="title">{{title}}</slot>
        <i
          class="iam-collapse-item__arrow iam-icon-chevron-right"
          :class="{'is-active': isActive}">
        </i>
      </div>
    </div>
    <!--iam-collapse-transition-->
      <div
        class="iam-collapse-item__wrap"
        v-show="isActive"
        role="tabpanel"
        :aria-hidden="!isActive"
        :aria-labelledby="`iam-collapse-head-${id}`"
        :id="`iam-collapse-content-${id}`"
      >
        <div class="iam-collapse-item__content">
          <slot></slot>
        </div>
      </div>
    <!--/iam-collapse-transition-->
  </div>
</template>
<script>
// import iamCollapseTransition from 'element-ui/src/transitions/collapse-transition'
import Emitter from '../../emitter'
// import { generateId } from 'element-ui/src/utils/util'
export default {
  name: 'iamCollapseItem',
  componentName: 'iamCollapseItem',
  mixins: [Emitter],
  // components: { iamCollapseTransition },
  data () {
    return {
      contentWrapStyle: {
        height: 'auto',
        display: 'block'
      },
      contentHeight: 0,
      focusing: false,
      isClick: false
    }
  },
  inject: ['collapse'],
  props: {
    title: String,
    name: {
      type: [String, Number],
      default () {
        return this._uid
      }
    }
  },
  computed: {
    isActive () {
      return this.collapse.activeNames.indexOf(this.name) > -1
    },
    id () {
      return this.generateId()
    }
  },
  methods: {
    handleFocus () {
      setTimeout(() => {
        if (!this.isClick) {
          this.focusing = true
        } else {
          this.isClick = false
        }
      }, 50)
    },
    handleHeaderClick () {
      this.dispatch('iamCollapse', 'item-click', this)
      this.focusing = false
      this.isClick = true
    },
    handleEnterClick () {
      this.dispatch('iamCollapse', 'item-click', this)
    },
    generateId () {
      var d = new Date().getTime()
      if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now() // use high-precision timer if available
      }
      var newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16)
      })
      return newGuid
    }
  }
}
</script>

<style lang="scss">
.toolbar-cities,
.toolbar-pagination,
.toolbar-categories{
   padding-bottom: 1rem;

   .buttons{
     justify-content: center;
     .page{
       display: none;
     }
   }
}

@media (min-width: 1088px) {
  .toolbar{
   display:flex;
   flex-wrap: wrap;
   .toolbar-cities{
     padding-top: 1rem;
   }
   .toolbar-pagination{
     text-align: center;
     padding-top: 1rem;
    margin-left: auto;
    .page{
      display: inline-flex;
    }
   }
   .toolbar-categories{
    width:100%;
    padding: 1rem 0;
     .buttons{
      justify-content:flex-start;
    }
   }
  }
}


</style>
<template>
  <div>
    <div class="toolbar" v-if="filters">
      <div class="toolbar-cities">
        <div class="buttons">
          <a class="button is-primary" v-for="(city, index) in ['All','Belfast','Liverpool','Newcastle','Dubai']" :key="index" @click="setFilter(city)" :class="{ 'is-link': city_filter === city }"> {{ city }}</a>
        </div>
      </div>
      <div class="toolbar-pagination">
        <nav class="buttons" v-if="pageCount > 1">
          <a @click="prev()" class="button">Previous</a>
            <template v-for="p in pageCount">
              <a v-if="pageNo == p" @click="page(p)" class="button page is-primary">{{p}}</a>
              <a v-else @click="page(p)" class="button page">{{p}}</a>
            </template>
          <a @click="next()" class="button">Next page</a>
        </nav>
      </div>
      <div class="toolbar-categories">
        <div class="buttons" v-if="categories.length > 0">
          <a class="button" v-for="(cat, index) in categories" :key="index" @click="setCategory(cat.name)" :class="{ 'is-link': category.indexOf(cat.name) >= 0 }"> {{ cat.name }}</a>
        </div>
      </div>
    </div>

    <div v-if="filteredData.length > 0">
    <div class="columns is-multiline">
        <div v-for="(item, index) in items" :key="index" class="column is-6-tablet is-4-desktop">
          <div class="card">
            <div @click="Show(item)" class="card-image">
              <div class="card-image__img">
                <img :src="item.image" :alt="item.title">
              </div>
              <div class="card-image__content">
                <div class="card-image__subtitle">
                  <span v-for="(city, index) in item.cities" :key="index">
                    {{ city.name }}<template v-if="index + 1 < item.cities.length">, </template>
                  </span>
                </div>

              </div>
            </div>
            <h3 class="card-image__title">{{ item.title }}</h3>
          </div>
      </div>
    </div>
  </div>
  <div v-else>
    <p>No results</p>
  </div>


  <div v-show="active" class="popup">
    <div class="popup__container">
      <button @click="Close()" class="popup__close">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" data-reactid="1376"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
      </button>
      <div class="popup__content" style="position: relative; display: flex; justify-content: space-between; flex-direction: column;background:#302736;">
          <div :style="'background:no-repeat center;background-size:cover;background-image:url(' +active.image+ '); position:absolute; z-index:0; width:100%;height:100%;top:0;left:0;opacity:0.5;'" ></div>

        <div style="position:relative;z-index:1; padding-bottom:30%;" class="has-text-white">
          Avaible in:
          <span v-for="(city, index) in active.cities" :key="index">
            <a class="has-text-white" :href="city.url">{{ city.name }}</a><template v-if="index + 1 < active.cities.length">, </template>
          </span>
        </div>
        <div style="position:relative;z-index:1;">
          <a class="button is-link" v-if="active.booking" :href="active.booking" target="_blank" rel="nofollow noreferrer noindex">Book Now</a>
          <button class="button is-link" v-else type="button" @click="toggleForm()">Enquire Online</button>
        </div>
      </div>
      <div class="popup__content">
        <div v-show="!showform">
          <h3 style="margin:0;padding:0;margin-bottom:1rem;" class="title has-text-weight-bold">{{ active.title }}</h3>
          <div class="content" v-html="active.content"></div>
        </div>
        <div v-show="showform">

          <form  @submit.stop>
            <div class="field">
            <div class="field-body">

            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input name="name" v-validate="'required'" v-model="form.name" class="input" type="text" placeholder="Name">
                <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-validate="'required|email'" v-model="form.email" class="input" type="email" name="email" placeholder="Email">
                <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
              </div>
            </div>

          </div>
          </div>
          <div class="field">
          <div class="field-body">

          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <div class="select">
                <select v-validate="'required'" v-model="form.city" name="city">
                  <option disabled value="">Please choose city</option>
                  <option v-for="(city, index) in active.cities" :key="index">{{ city.name }}</option>
                </select>
              </div>
              <span v-show="errors.has('city')" class="help is-danger">{{ errors.first('city') }}</span>
            </div>
          </div>

            <div class="field">
              <label class="label">Phone</label>
              <div class="control">
                <input v-validate="'required'" v-model="form.phone" class="input" type="tel" name="phone" placeholder="Phone number">
                <span v-show="errors.has('phone')" class="help is-danger">{{ errors.first('phone') }}</span>
              </div>
            </div>

          </div>
          </div>

            <div class="field">
              <label class="label">Message</label>
              <div class="control">
                <textarea name="message" v-validate="'required'" v-model="form.message" class="textarea" placeholder="Message"></textarea>
                <span v-show="errors.has('message')" class="help is-danger">{{ errors.first('message') }}</span>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="checkbox">
                  <input name="agree" type="checkbox" v-validate="'required'">
                  I agree to the <a href="/terms-and-conditions">terms and conditions</a>
                </label>
                <span v-show="errors.has('agree')" class="help is-danger">{{ errors.first('agree') }}</span>
              </div>
            </div>

            <div class="field is-grouped">
              <div class="control">
                <button type="button" class="button" @click="Close()">Cancel</button>
                <button :disabled="errors.any()" type="button" class="button is-link" @click="SendMessage()">Submit</button>
              </div>
            </div>
          </form>

        </div>

      </div>
    </div>
    <div class="vue-lb-overlay"  @click="Close()" style="opacity:1"></div>
  </div>

  </div>

</template>
<script>
export default {
  name: 'SpecialOffers',
  props: ['city','limit','filters','type','featured','home'],
  data () {
    return {
      form: {
        subject: '',
        id: null,
        city: ''
      },
      showform: false,
      city_filter: 'All',
      categories: [],
      category: [],
      active: false,
      offers: [],
      items: [],
      pageNo: 1,
      pageSize: 12,
      pageCount: 0
    }
  },
  computed: {
    filteredData() {
      let filtered = this.offers
      let filteredDataByCaregory = []
      let filteredDataByCity = []

      if (this.city_filter !== 'All') {
        filteredDataByCity = filtered.filter((item) => {
          let foundCity = item.cities.findIndex((c) => {
            return c.name === this.city_filter
          })
          return foundCity !== -1
        })
        filtered = filteredDataByCity
      }

      if (this.category.length > 0) {
        filteredDataByCaregory = filtered.filter((item) => {
          let foundCategory = item.categories.findIndex((c) => {
            /* return this.category.every((val) => val === c.name  ) */
            return this.category.some((val) => val === c.name  )
          })
          return foundCategory !== -1
        })

        filtered = filteredDataByCaregory;
      }
      return filtered
    }
  },
  methods: {
    Reset () {
      this.form = {
        subject: this.active.title,
        id: this.active.id,
        city: ''
      } // Clear input fields.
      // this.errors.clear()
    },
    SendMessage () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          axios.post('/enquire/offer', this.form)
            .then(response => {
              this.Reset()
              this.showform = false

              // Clear input fields.
              //this.$notify({
              //  title: response.data.status,
              //  message: response.data.message
            //  })
/*
            this.$gtag.reportConversion({
              'send_to': 'AW-793825595/noYZCJzvg5sBELuiw_oC'
            })
*/
            })
            .catch(err => {
            //  this.$notify({
            //    title: err.response.data.status,
            //    message: err.response.data.message
            //  })
            })
        }
      })
    },
    toggleForm () {
      this.showform =! this.showform
    },
    init(){
      this.virtualService({
        pageNo: this.pageNo,
        pageSize: this.pageSize
      })
    },
    page(pageNo) {
      this.virtualService({
        pageNo: pageNo,
        pageSize: this.pageSize
      })
    },
    first() {
      this.pageNo = 1
      this.virtualService({
        pageNo:this.pageNo,
        pageSize:this.pageSize
      })
    },
    last() {
      this.pageNo = this.pageCount
      this.virtualService({
        pageNo:this.pageNo,
        pageSize:this.pageSize
      })
    },
    prev() {
      if(this.pageNo > 1){
        this.pageNo -= 1
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        })
      }
    },
    next() {
      if(this.pageNo < this.pageCount) {
        this.pageNo += 1
        this.virtualService({
          pageNo:this.pageNo,
          pageSize:this.pageSize
        })
      }
    },
    virtualDataFromDb() {
      return this.offers
    },
    count(condition){
      return this.offers.length
    },
    setFilter(filter){
      this.items = [],
      this.pageCount = 0
      this.pageNo = 1
      this.city_filter = filter
      this.$nextTick(() => {
        this.init()
      })
    },
    setCategory(filter){
        if (this.category.indexOf(filter) < 0) {
            this.category.push(filter)
        }else{
          this.category.splice(this.category.indexOf(filter), 1)
        }
        this.items = [],
        this.pageCount = 0
        this.pageNo = 1
        this.$nextTick(() => {
          this.init()
        })

    },
    queryFromVirtualDB(condition, startRow, endRow){

      var result = []
      var condition = {}

      var data = this.filteredData
      var count = this.filteredData.length
      for(var i = startRow - 1; i < endRow; i++) {
        if(i < count){
          result.push(data[i])
        }
      }
      return result
    },
    virtualService(params){
      var result = []
      var condition = {}
      var pageNo = params.pageNo
      var pageSize = params.pageSize
      var pageCount = Math.ceil(this.filteredData.length / pageSize)

      if (pageNo == 0) pageNo = 1
      if (pageNo < 0) pageNo = pageCount
      else if (pageNo > pageCount) pageNo = pageCount
      var startRow = (pageNo - 1) * pageSize + 1
		  var endRow = startRow + pageSize - 1
      var data = this.queryFromVirtualDB(condition, startRow, endRow)

      // set result
      this.items = data
      this.pageNo = pageNo
      this.pageCount = pageCount
    },
    Show(item){
      this.active = item
      this.Reset()
      document.querySelector('html').classList.toggle('no-scroll', true)
    },
    Close() {
      this.showform = false
      this.active = false
      document.querySelector('html').classList.toggle('no-scroll', false)
    },
    DisplayAdvert(id) {
      setTimeout(() => {
        if(this.home && !this.$cookie.get('__advert')){
          const item = this.items.find(ad => ad.id === id)
          if(item){
            this.$cookie.set('__advert', item.id, { expires: '1D'});
            this.Show(item)
          }
        }
      }, 6000);
    }
  },
  mounted () {

    if(this.filters) {
      axios
      .get('/api/categories' )
      .then(response => {
          this.categories = response.data
      });
    }

    axios
    .get('/api/offers/'+ this.city +'/' + this.limit+'?type='+ this.type +'&featured=' + this.featured )
    .then(response => {
        this.offers = response.data
        this.init()
        this.DisplayAdvert(63)
    });

  }
}
</script>
